
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import VDataTable from "@monade/vue-components/src/components/VDataTable.vue";
import { DataPlot, User } from "../../models";
import ConfirmModal from "@/components/Common/VConfirmModal.vue";
import VPaginator from "@/components/Common/VPaginator.vue";
import DataPlotAdminMixin, {
  DataPlotAdminTags,
} from "@/mixins/http/admin/DataPlotAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import { currentUserStore, httpStore } from "@/store/typed";
import RequestData from "@/store/types/RequestData";
import DataPlotCreateModal from "@/components/DataPlot/Modal.vue";
import EmptyView from "@/components/Common/EmptyView.vue";
import { showToast, showErrorToast } from "@/utils/Toast";
import DataPlotErrorsModal from "@/components/DataPlot/ErrorsModal.vue";
const indexTag = DataPlotAdminTags.DataPlotIndex;
const destroyTag = DataPlotAdminTags.DataPlotDestroy;

@Component({
  components: {
    VDataTable,
    ConfirmModal,
    PageSection,
    VPaginator,
    DataPlotErrorsModal,
    EmptyView,
    DataPlotCreateModal,
  },
})
export default class DataPlotList extends Mixins(DataPlotAdminMixin) {
  @RegisterHttp(indexTag) readonly indexRequest!: RequestData;
  @RegisterHttp(destroyTag) readonly destroyRequest!: RequestData;
  @Ref() readonly confirmModal!: any;
  @Ref() readonly dataPlotCreateModal!: DataPlotCreateModal;
  @Ref() readonly errorsModal!: DataPlotErrorsModal;
  @Prop() readonly title!: string;
  @Prop() readonly backLink!: boolean;
  @Prop() readonly companyId!: string;
  @Prop() readonly organizationId!: string;
  @Prop() readonly withPadding!: boolean;
  @Prop() readonly filter!: any;

  private items: DataPlot[] = [];

  private error = false;

  private page = 0;

  get tables() {
    return [
      { title: "In uso", values: this.currentDataPlots },
      { title: "Archiviati", values: this.unusedDataPlots },
    ];
  }

  get columns() {
    return [
      { name: "realm", label: "Lotto" },
      { name: "status", label: "Stato" },
      { name: "validAt", label: "Data di riferimento" },
      { name: "file", label: "File" },
      { name: "actions", label: "" },
    ];
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  mounted() {
    this.load();
  }

  onPageChanged(page: number) {
    this.page = page;
    this.load();
  }

  destroyTag(id: string) {
    return `destroy_tag:${id}`;
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get meta() {
    return this.indexRequest?.meta;
  }

  async rollback(id: string) {
    const [data, errors] = await safeAsync(this.rollbackDataPlot(id));

    if (!errors) {
      this.load();
    } else {
      showToast("Si è verificato un errore durante il rollback", {
        type: "error",
      });
    }
  }

  itemLoading(id: string) {
    return httpStore.status[this.destroyTag(id)]?.loading;
  }

  edit(user: User) {
    (this.dataPlotCreateModal as any).edit(user);
  }

  add() {
    (this.dataPlotCreateModal as any).add();
  }

  openErrorsModal(dataPlot: DataPlot) {
    (this.errorsModal as any).open(dataPlot);
  }

  async performDelete(id: string) {
    const [data, errors] = await safeAsync(this.destroyDataPlot(id));

    if (!errors) {
      this.afterDelete(id);
    } else {
      showToast("Si è verificato un errore durante la cancellazione", {
        type: "error",
      });
    }
  }

  confirmDelete(id: string) {
    this.confirmModal.confirm(
      "Sei sicuro?",
      "Questa azione non è reversibile",
      id
    );
  }

  afterDelete(id: string) {
    this.items = this.items.filter((dp: DataPlot) => dp.id !== id);
  }

  get currentDataPlots() {
    return this.items.filter((o: DataPlot) => o.current);
  }

  get unusedDataPlots() {
    return this.items.filter((o: DataPlot) => !o.current);
  }

  async load() {
    const [data, errors] = await safeAsync(this.getDataPlots());

    if (!errors) {
      this.items = data;
      this.error = false;
    } else {
      this.error = true;
    }
  }
}
