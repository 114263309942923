
import { UserTags } from "@/mixins/http/UserMixin";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import WithErrors from "@/directives/WithErrors";
import { currentUserStore } from "../../store/typed";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import { DataPlotPayload } from "../../models/DataPlot";
import RealmSelect from "@/components/Realm/Select.vue";
@Component({
  directives: {
    WithErrors,
  },
  components: {
    VFileUploader,
    RealmSelect,
  },
})
export default class DataPlotForm extends Vue {
  @Prop({ required: true }) readonly value!: DataPlotPayload;

  private dataPlot: DataPlotPayload = this.value;

  get httpTag() {
    return UserTags.UserUpsert;
  }
  get fileName() {
    return this.value?.file?.name;
  }

  onRealmSelected(id: string) {
    this.dataPlot.realmId = id;
  }

  @Watch("dataPlot", { deep: true })
  onFieldsChanged() {
    this.$emit("input", this.dataPlot);
  }
}
