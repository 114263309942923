
import { Vue, Component, Prop, Ref, Mixins } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import DataPlotForm from "@/components/DataPlot/Form.vue";
import DataPlotAdminMixin from "@/mixins/http/admin/DataPlotAdminMixin";
import { groupBy } from "@/utils/Chunker";
import { currentUserStore } from "@/store/typed";
import DataPlot, { DataPlotPayload } from "../../models/DataPlot";
@Component({
  components: {
    VModal,
    DataPlotForm,
  },
})
export default class DataPlotErrorsModal extends Mixins(DataPlotAdminMixin) {
  @Ref() readonly modal!: VModal;

  private dataPlot: DataPlot | null = null;

  errorFromCode(code: string) {
    switch (code) {
      case "invalid":
        return "La riga contiene degli errori";
      case "wrong_realm":
        return "Iniziativa inserita nel lotto sbagliato";
    }

    return "Errore sconosciuto";
  }

  open(dataPlot: DataPlot) {
    this.dataPlot = dataPlot;
    (this.modal as any).show();
  }

  get errorsBySheet() {
    return groupBy(this.dataPlot.errorsMetadata, "sheetName");
  }

  get errors() {
    return this.dataPlot?.errorsMetadata;
  }

  hide() {
    (this.modal as any).hide();
  }

  reset() {
    this.dataPlot = null;
  }
}
