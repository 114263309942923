
import { Vue, Component, Prop, Ref, Mixins } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import DataPlotForm from "@/components/DataPlot/Form.vue";
import DataPlotAdminMixin from "@/mixins/http/admin/DataPlotAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import DataPlot, { DataPlotPayload } from "../../models/DataPlot";
@Component({
  components: {
    VModal,
    DataPlotForm,
  },
})
export default class DataPlotModal extends Mixins(DataPlotAdminMixin) {
  @Ref() readonly modal!: VModal;

  private dataPlot: DataPlot | null = null;

  private dataPlotPayload: DataPlotPayload | null = null;

  get actionName() {
    return "Importa";
  }

  private onSubmit() {
    this.httpCreateUser();
  }

  private async httpCreateUser() {
    const formData = new FormData();
    if (this.dataPlotPayload.file) {
      formData.append("file", this.dataPlotPayload.file?.file);
    }
    formData.append("realmId", this.dataPlotPayload.realmId);
    formData.append("validAt", this.dataPlotPayload.validAt.toString());
    const [data, errors] = await safeAsync(this.createDataPlot(formData));

    if (errors) {
      showErrorToast("Errore durante la creazione del tracciato");
      return;
    } else {
      this.reset();
    }
    if (data.status === "errors") {
      showErrorToast("Ci sono stati problemi nell'importazione");
    } else {
      showToast("Creazione avvenuta con successo!");
    }
    this.$emit("created", data);
    this.hide();
  }

  add() {
    this.dataPlotPayload = { file: null, realmId: null, validAt: new Date() };
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  reset() {
    this.dataPlot = null;
    this.dataPlotPayload = null;
  }
}
