import DataPlot from "@/models/DataPlot";
import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";

export enum DataPlotAdminTags {
  DataPlotCreate = "DataPlotCreate",
  DataPlotUpdate = "DataPlotUpdate",
  DataPlotDestroy = "DataPlotDestroy",
  DataPlotRollback = "DataPlotRollback",
  DataPlotIndex = "DataPlotIndex",
}

@Component
export default class DataPlotsAdminMixin extends Vue {
  getDataPlots(data: any = {}): Promise<DataPlot[]> {
    const url = `${SD_BASE_API_URL}/admin/data_plots`;
    return httpStore.request({
      tag: DataPlotAdminTags.DataPlotIndex,
      url: url,
      method: "GET",
      params: data,
    }) as Promise<DataPlot[]>;
  }

  createDataPlot(data: any): Promise<DataPlot> {
    const url = `${SD_BASE_API_URL}/admin/data_plots`;

    return httpStore.request({
      tag: DataPlotAdminTags.DataPlotCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<DataPlot>;
  }

  rollbackDataPlot(id: string): Promise<DataPlot> {
    const url = `${SD_BASE_API_URL}/admin/data_plots/${id}/rollback`;

    const data = {};

    return httpStore.request({
      tag: DataPlotAdminTags.DataPlotRollback,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<DataPlot>;
  }

  destroyDataPlot(id: string): Promise<DataPlot> {
    const url = `${SD_BASE_API_URL}/admin/data_plots/${id}`;

    return httpStore.request({
      tag: DataPlotAdminTags.DataPlotDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<DataPlot>;
  }
}
